import { useMemo } from 'react';

type AnchorTextBatchFooterProps = {
  data: AnchorTextTotal | null;
  withCheckbox?: boolean;
  tableData: (AnchorTextBatch & { totals: { match_type: { exact: number; generic: number; partial: number } } })[];
};

const AnchorTextBatchTableFooter = ({ data, withCheckbox = false, tableData }: AnchorTextBatchFooterProps) => {
  const totalAnchorText = useMemo(() => {
    return tableData.reduce((acc, batch) => {
      const { exact, generic, partial } = batch.totals.match_type;
      const batchTotal = (exact + generic + partial) * batch.selected_term;
      return acc + batchTotal;
    }, 0);
  }, [tableData]);

  if (!data) return null;
  const { exact, generic, partial, total } = data;

  return (
    <tr className='h-8  bg-blue-100 py-2 pl-10 text-left text-sm'>
      {withCheckbox && <td></td>}
      <td className='pl-4'>Grand Total:</td>
      <td className='pl-4'>{exact} Links</td>
      <td className='pl-4'>{partial} Links</td>
      <td className='pl-4'>{generic} Links</td>
      <td className='pl-4'>{total}</td>
      <td className='pl-4'>{totalAnchorText}</td>
      <td></td>
      <td></td>
    </tr>
  );
};

export default AnchorTextBatchTableFooter;
