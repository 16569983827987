import { STEPS } from '@/constants/defaultValues';
import { useSteps } from '@/hooks/steps';
import { useToggle } from '@/hooks/toggle';
import { Page } from '@/layouts';
import store from '@/store';
import campaignAPI, { useGetAnchorTextBatchesQuery, useGetCampaignConfigQuery, useSaveCampaignConfigMutation } from '@/store/campaign/campaign.api';
import { selectCampaign, selectCampaignAnalysis, selectScenarios, selectSteps } from '@/store/campaign/campaign.selector';
import { setIsDirty } from '@/store/campaign/campaign.slice';
import { AppDispatch } from '@/store/store';
import { getParsedAnalysisData } from '@/utils/campaigns';
import { FC, Fragment, Suspense, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionFunction, Await, LoaderFunction, defer, redirect, useLoaderData, useNavigate, useNavigation, useParams } from 'react-router-dom';
import { AnalysisFooter, AnalysisTopbar } from '../../components';
import AnchorTextGeneratorPageSkeleton from './AnchorTextGeneratorPageSkeleton';
import { Disclosure } from './components';
import SkipModal from './components/SkipModal';
import { updateCampaignScenarioMutation } from '@/store/reducers';
import { Breadcrumbs } from '@/components';

type AnchorTextGeneratorPageData = [APIListResponse<Scenario>, CampaignJSON, AnalysisDataResponse];

const anchorTextGeneratorPageLoader: LoaderFunction = async ({ params }) => {
  const { campaignId } = params as { campaignId: string };

  const scenariosPromise = store.dispatch(campaignAPI.endpoints.getCampaignScenarios.initiate({ campaignId, is_approved: true }));
  const campaignPromise = store.dispatch(campaignAPI.endpoints.getCampaign.initiate({ campaignId }));
  const batchesPromise = store.dispatch(campaignAPI.endpoints.getAnchorTextBatches.initiate({ campaignId, pageSize: 500 }));

  const scenarios = await scenariosPromise.unwrap();
  const approvedScenario = scenarios.results.find(({ is_approved }) => is_approved);

  if (!approvedScenario) {
    const nextStep = STEPS[1];
    await store.dispatch(campaignAPI.endpoints.saveCampaignConfig.initiate({ campaignId, config: { user_progress: { last_step: nextStep } } }));
    return redirect(`../${nextStep}`);
  }

  // const campaignAnalysisPromise = store.dispatch(
  //   campaignAPI.endpoints.getCampaignAnalysis.initiate({ campaignId, filter_excluded_domains: true, filter_scenario_id: approvedScenario?.id }, { forceRefetch: true }),
  // );

  try {
    const data = await Promise.all([scenariosPromise.unwrap(), campaignPromise.unwrap()]);
    return defer({ data });
  } catch (error) {
    return {
      campaignId,
      error: error as APIError,
    };
  } finally {
    scenariosPromise.unsubscribe();
    campaignPromise.unsubscribe();
    // campaignAnalysisPromise.unsubscribe();
    batchesPromise.unsubscribe();
  }
};

const anchorTextGeneratorPageAction: ActionFunction = async () => {};

type InitialSelectedMethodStateValueArgs = {
  campaignAnalysis?: AnalysisData[];
  approvedScenario?: Scenario;
  hasAnchorText: boolean;
};
const getInitialSelectedMethodStateValue = ({ hasAnchorText, approvedScenario, campaignAnalysis }: InitialSelectedMethodStateValueArgs) => {
  if (!campaignAnalysis || !approvedScenario) {
    return new Map();
  }

  const parsedAnalysis = getParsedAnalysisData({ data: campaignAnalysis, scenario: approvedScenario });
  const recommendedData: UrlAnchorTextTableColumns[] = [];

  for (const data of parsedAnalysis) {
    recommendedData.push({
      ...data,
      exact_match: (hasAnchorText ? data.exact_match : data.recommended?.exact_match) || 0,
      partial_match: (hasAnchorText ? data.partial_match : data.recommended?.partial_match) || 0,
      generic_match: (hasAnchorText ? data.generic_match : data.recommended?.generic_match) || 0,
    });
  }

  return new Map([
    ['bulk_edit', recommendedData],
    ['individual_edit', recommendedData],
    ['recommended', recommendedData],
  ]);
};

const AnchorTextGeneratorPage: FC = () => {
  const navigation = useNavigation();
  const { campaignId } = useParams() as { campaignId: string };
  const { data, error } = useLoaderData() as PageLoaderDefData<AnchorTextGeneratorPageData>;
  const [updateCampaignConfig] = useSaveCampaignConfigMutation();
  const dispatch = useDispatch<AppDispatch>();
  const scenarios = useSelector(selectScenarios);
  const campaign = useSelector(selectCampaign);
  const campaignAnalysis = useSelector(selectCampaignAnalysis);
  const isDirty = useSelector(selectSteps)['anchor-text-generator'].isDirty;
  const { data: batches } = useGetAnchorTextBatchesQuery({ campaignId, pageSize: 500 });
  const navigate = useNavigate();
  const { previousStep, nextStep, analysisSteps } = useSteps();

  const { data: config } = useGetCampaignConfigQuery({ campaignId });

  const approvedScenario = useMemo(() => scenarios.find((s) => s.is_approved), [scenarios]);

  const [selectedMethod, setSelectedMethod] = useState<MatchMethods>(campaign?.config?.selected_method || 'recommended');

  const [selectedMethodState, setSelectedMethodState] = useState<Map<MatchMethods, UrlAnchorTextTableColumns[]>>(() =>
    getInitialSelectedMethodStateValue({
      campaignAnalysis,
      approvedScenario,
      hasAnchorText: Boolean(config?.data.has_anchor_texts),
    }),
  );

  const [currentCard, setCurrentCard] = useState<'priority-keywords' | 'anchor-text-match' | undefined>(undefined);

  const { value: isSkipModalOpen, toggle: toggleSkipModal } = useToggle();

  const hasAnchorTexts = useMemo(() => Boolean(config?.data.has_anchor_texts), [config]);
  const errorMessage = `Something went wrong, ${error ? error.status : '?'} - ${error && error.data ? error.data.message : '?'}`;

  const handleSave = useCallback(async () => {
    if (!approvedScenario) {
      return;
    }

    if (!currentCard || currentCard === 'priority-keywords') {
      return;
    }

    const urls = (selectedMethodState.get(selectedMethod) || []).map((url) => {
      const counts = { generic: url.generic_match, partial: url.partial_match, exact: url.exact_match };
      return { id: url.url_id, counts, is_allocated: url.is_allocated };
    });

    const newURLAnchorCounts = approvedScenario.urls.map((url) => {
      const newURL = urls.find((u) => u.id === url.url_id);

      if (newURL) {
        return {
          ...url,
          is_allocated: newURL.is_allocated,
          anchor_text: {
            exact: newURL.counts.exact,
            partial: newURL.counts.partial,
            generic: newURL.counts.generic,
          },
        };
      }

      return url;
    });

    await dispatch(updateCampaignScenarioMutation({ campaignId, scenario: { ...approvedScenario, urls: newURLAnchorCounts } }));
    dispatch(setIsDirty({ step: 'anchor-text-generator', isDirty: false }));
  }, [approvedScenario, selectedMethodState, selectedMethod, campaignId, dispatch, currentCard]);

  const handleNext = async () => {
    if (!hasAnchorTexts && !isSkipModalOpen) {
      return toggleSkipModal(true);
    }

    navigate(`../${nextStep}`);
  };

  const handlePrevious = async () => {
    navigate(`../${previousStep}`);
  };

  const handleSkip = async () => {
    const summaryStep = analysisSteps.find((step) => step.slug === 'summary');
    if (!summaryStep) {
      return;
    }
    await updateCampaignConfig({ campaignId, config: { user_progress: { last_step: 'summary' } } });

    navigate(summaryStep.url);
  };

  if (navigation.state === 'loading') {
    return <AnchorTextGeneratorPageSkeleton />;
  }

  return (
    <Suspense fallback={<AnchorTextGeneratorPageSkeleton />}>
      <Await resolve={data} errorElement={error && <p>{errorMessage}</p>}>
        <Fragment>
          <Page>
            <AnalysisTopbar onSave={handleSave} isDirty={isDirty} />
            <Page.Body>
              <Breadcrumbs items={[{ label: 'Batch List', url: '../anchor-text-batches', disabled: batches?.results.length === 0 }, { label: 'Anchor Text Generator' }]} />
              <h2 className='pb-6 text-left font-semibold'>Anchor Text Generator</h2>
              <Disclosure
                setCurrentCard={setCurrentCard}
                selectedMethod={selectedMethod}
                setSelectedMethod={setSelectedMethod}
                selectedMethodState={selectedMethodState}
                setSelectedMethodState={setSelectedMethodState}
              />
            </Page.Body>
            <Page.Footer>
              <AnalysisFooter onSave={handleSave} onNext={handleNext} nextText={hasAnchorTexts ? 'Next' : 'Skip'} onPrevious={handlePrevious} />
            </Page.Footer>
          </Page>
          {isSkipModalOpen && <SkipModal isOpen={isSkipModalOpen} onClose={() => toggleSkipModal(false)} onSkip={handleSkip} />}
        </Fragment>
      </Await>
    </Suspense>
  );
};

export default AnchorTextGeneratorPage;
export { anchorTextGeneratorPageAction, anchorTextGeneratorPageLoader };
