import { FONT_SIZES, MODAL_CLASSNAMES_WIDTH_IN_REM, SCREEN_SIZES } from '@/constants/layout';

// REM Value is the font size of the HTML tag, we always use 16 or 14 based on the media query breakpoint
const getRemValue = (screenWidth: number) => {
  return screenWidth >= SCREEN_SIZES.xxl ? FONT_SIZES.base : FONT_SIZES.sm;
};

const getModalWidth = (screenWidth: number, modalClass: keyof typeof MODAL_CLASSNAMES_WIDTH_IN_REM) => {
  const remValue = getRemValue(screenWidth);
  return MODAL_CLASSNAMES_WIDTH_IN_REM[modalClass] * remValue;
};

export { getModalWidth };
