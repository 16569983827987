import { Dispatch, PropsWithChildren, SetStateAction, useCallback, useState } from 'react';
import { Row, Table } from '@tanstack/react-table';
import cn from '@/utils/style';

type LineClampClassName = `line-clamp-${number | 'none'}`;

type TruncatedTextCellProps<T> = { row: Row<T>; table: Table<T>; lineClamp?: LineClampClassName } & PropsWithChildren;

type Meta = {
  rowsMap: Record<number, boolean>;
  setRowsMap: Dispatch<SetStateAction<Record<number, boolean>>>;
};

const TruncatedTextCell = <T extends unknown & { id: number }>({ row, table, children, lineClamp = 'line-clamp-2' }: TruncatedTextCellProps<T>) => {
  const tableMeta = table.options.meta as Meta;
  const expanded = tableMeta.rowsMap[row.original.id];
  const [shouldClamp, setShouldClamp] = useState(true);

  const toggleText = () => {
    tableMeta.setRowsMap((prev) => ({ ...prev, [row.original.id]: !prev[row.original.id] }));
  };

  const getShouldClamp = useCallback((element: HTMLDivElement | null) => {
    if (!element) return;
    const isClamped = element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth;

    setShouldClamp(isClamped);
  }, []);

  return (
    <div className='flex flex-col items-start'>
      <div ref={getShouldClamp} className={cn(!expanded && lineClamp, 'w-full text-start')}>
        {children}
      </div>
      {shouldClamp && (
        <button className='inline-block w-full text-start text-blue-500' onClick={toggleText}>
          {expanded ? 'View Less' : 'View More'}
        </button>
      )}
    </div>
  );
};

export default TruncatedTextCell;
