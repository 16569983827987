import DATA_PARTNERS from '@/constants/dataPartners';
import cn from '@/utils/style';

type Partner = typeof DATA_PARTNERS[number];

const DataPartnersFooter = ({ className }: { className?: string }) => {
  const renderPartner = ({ name, logo, metrics }: Partner, index: number) => {
    return (
      <div className='flex items-center gap-8' key={name}>
        <div className='flex items-center'>
          <img src={logo} className={cn('h-6 w-auto pr-3', name === 'Semrush' && 'h-10 pr-0')} />
          <p>{metrics.join(', ')}</p>
        </div>
        {index !== DATA_PARTNERS.length - 1 && <div className='h-1/2 w-0.5 rounded-leap bg-slate-200' />}
      </div>
    );
  };

  return <section className={cn('flex justify-start gap-8 rounded-leap bg-white px-4 py-2', className)}>{DATA_PARTNERS.map(renderPartner)}</section>;
};

export default DataPartnersFooter;
