import { FC, Suspense, useState, Fragment, useEffect, useMemo, useCallback } from 'react';
import { getOverviewTableData } from '@/utils/campaigns';
import { VirtualizedTable } from '@/components';
import useOverviewColumns from '../../Campaigns/pages/AnalysisPage/components/OverviewTable/columns';
import { ColumnFiltersState, FilterFnOption, Row } from '@tanstack/react-table';
import { useTable } from '@/hooks/table';

interface AnalysisTableProps {
  data: AnalysisDataResponse;
}

const AnalysisTable: FC<AnalysisTableProps> = ({ data }) => {
  const [columnFiltersState, setColumnFiltersState] = useState<ColumnFiltersState>([]);
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const overviewGlobalFilter = (row: Row<AnalysisData>, _columnId: keyof AnalysisData, filterValue: string) => {
    return row.original.targetPage.includes(filterValue);
  };

  const customAnalysisData = useMemo(() => {
    return getOverviewTableData({ scenarioUrls: [], campaignAnalysis: data.results });
  }, [data]);

  const columns = useOverviewColumns({
    hasAnchorsText: true,
    hasKeywords: true,
    showTargetOnSearchVolume: true,
  });

  const [table] = useTable({
    data: customAnalysisData,
    columns,
    defaultSorting: [{ id: 'totalVolume', desc: true }],
    tableCustomOptions: ['allowFilters', 'allowSelect', 'allowSort'],
    tableOptions: {
      onColumnFiltersChange: setColumnFiltersState,
      onGlobalFilterChange: setGlobalFilterValue,
      globalFilterFn: overviewGlobalFilter as FilterFnOption<AnalysisData>,
      initialState: {
        columnPinning: {
          left: ['closeToGap', 'scenarioLinksToBuild'],
        },
      },
      state: {
        columnVisibility: {
          closeToGap: false,
          scenarioLinksToBuild: false,
        },
        columnFilters: columnFiltersState,
        globalFilter: globalFilterValue,
      },
      meta: {
        toggleUrl: (url: ScenarioUrl, isEditAllScenarios: boolean) => {},
        setUrlLinksToBuild: ({ url_id, links_to_build, url }: { url_id: number; links_to_build: number; url: ScenarioUrl }) => {},
        setUrlCloseToGap: ({ url_id, close_to_gap, url }: { url_id: number; close_to_gap: number; url?: ScenarioUrl }) => {},
        onBeforeNavigate: () => {},
        createLCR: async (url: string) => {},
      },
    },
  });

  return (
    <div className='max-w-9xl border-1 mb-24 border'>
      <VirtualizedTable
        table={table}
        name='campaigns'
        loadingText='Loading data...'
        cellStyle='pt-10 pb-2 px-2 border-b border-gray-200'
        rowStyle='last-child:border-b-0'
        className='p-2'
        tableClass='shadow-none'
        containerClass='2xl:max-h-[calc(70vh-12rem)] max-h-[calc(60vh-12rem)]'
        headerClass='text-sm'
        itemHeight={86.5}
      />
    </div>
  );
};

export default AnalysisTable;
