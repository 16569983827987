import { Button, Select } from '@/components';
import { PAGINATION_STEPS } from '@/constants/pagination';
import { Table } from '@tanstack/react-table';
import { useCallback } from 'react';

interface PaginationProps<T> {
  table: Table<T>;
  onPageChange: (pageIndex: number, pageSize: number) => void;
  totalRows: number;
}

const Pagination = <T extends unknown>({ table, onPageChange, totalRows }: PaginationProps<T>) => {
  const page = Number(table.getState().pagination.pageIndex) + 1;
  const pageSize = table.getState().pagination.pageSize;

  const handleFirstPage = useCallback(() => onPageChange(1, pageSize), [onPageChange, pageSize]);
  const handlePreviousPage = useCallback(() => onPageChange(page - 1, pageSize), [onPageChange, page, pageSize]);
  const handleNextPage = useCallback(() => onPageChange(page + 1, pageSize), [onPageChange, page, pageSize]);
  const handleLastPage = useCallback(() => onPageChange(table.getPageCount(), pageSize), [table, onPageChange, pageSize]);

  const handlePaginationSizeChange = (value: number) => {
    onPageChange(1, value);
  };

  const pageFrom = table.getState().pagination.pageIndex * Number(pageSize) + 1;
  const pageTo = pageFrom + table.getRowModel().rows.length - 1;

  return (
    <div className='flex w-full items-center justify-between gap-2 rounded-b-[4px] bg-white p-4'>
      <div className='flex items-center gap-2'>
        <p>Rows per page: </p>
        <Select options={PAGINATION_STEPS} value={pageSize} onChange={handlePaginationSizeChange} className='w-20 border-none shadow-none' />
      </div>
      <div className='flex items-center gap-2'>
        <span className='mx-2 flex items-center gap-1'>
          <p>
            {pageFrom} - {pageTo} of {totalRows} Items
          </p>
        </span>
        <Button variant='outline-light' onClick={handleFirstPage} disabled={!table.getCanPreviousPage()}>
          {'<<'}
        </Button>
        <Button variant='outline-light' onClick={handlePreviousPage} disabled={!table.getCanPreviousPage()}>
          {'<'}
        </Button>
        <Button variant='outline-light' onClick={handleNextPage} disabled={!table.getCanNextPage()}>
          {'>'}
        </Button>
        <Button variant='outline-light' onClick={handleLastPage} disabled={!table.getCanNextPage()}>
          {'>>'}
        </Button>
      </div>
    </div>
  );
};

export default Pagination;
