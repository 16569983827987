import { DEFAULT_TERM } from '@/constants/defaultValues';
import { cutPercentage } from './numbers';
import * as yup from 'yup';

const trimmer = (i: string) => i.trim();

const parseCombinations = (combinations?: Array<Combination>) => {
  return (combinations || [])
    .map(({ keywords, url, location_code }) => {
      const cleanKeywords = (keywords || []).map(trimmer).filter(Boolean);

      return { keywords: cleanKeywords, url: url.trim(), location_code };
    })
    .filter((i) => i.url);
};

const parseUrls = (urls: string) => {
  return urls.split(/,|\n/).map(trimmer).filter(Boolean);
};

const getBaseUrl = () => {
  const [protocol, url] = window.location.href.split('//');
  const [host] = url.split('/');

  return `${protocol}//${host}`;
};

const getLinksToBuild = ({ lrdGap, closeToGap = 1, projLength = DEFAULT_TERM }: { lrdGap: number; closeToGap: number; projLength?: number }) => {
  return Math.ceil((lrdGap * (closeToGap / 100)) / projLength).toString();
};

const getCloseToGap = ({ lrdGap, linksToBuild, projLength = DEFAULT_TERM }: { lrdGap: number; linksToBuild: number; projLength?: number }) => {
  if (!linksToBuild || lrdGap <= 0) {
    return undefined;
  }

  const maxLinksToBuild = Math.ceil(lrdGap / projLength);
  const ctg = (linksToBuild / maxLinksToBuild) * 100;

  return Math.ceil(ctg) <= 102 ? cutPercentage(ctg).toString() : '';
};

const getLinksState = (scenario: Scenario) => {
  return scenario.urls.reduce(
    (acc: { ltbState: Record<string, string>; ctgState: Record<string, string> }, url) => {
      const urlLtb = url.links_to_build !== undefined ? Math.ceil(url.links_to_build).toString() : '';
      const calculatedLtb = url.close_to_gap && url.lrd_gap > 0 ? getLinksToBuild({ closeToGap: url.close_to_gap, lrdGap: url.lrd_gap, projLength: scenario.proj_length }) : '0';
      const ltb = urlLtb || calculatedLtb;
      acc.ltbState[url.url_id] = ltb;

      const closeToGap = getCloseToGap({ linksToBuild: Number(ltb), lrdGap: url.lrd_gap, projLength: scenario.proj_length });
      acc.ctgState[url.url_id] = closeToGap || (url.close_to_gap || '').toString();

      return acc;
    },
    { ltbState: {}, ctgState: {} },
  );
};

const transformCampaignUrlToScenarioUrl = (url: AnalysisData): ScenarioUrl => {
  return {
    url_id: url.targetPageId,
    close_to_gap: url.closeToGap || 0,
    links_to_build: url.linksToBuild || 0,
    target_page: url.targetPage,
    lrd_gap: url.lrdGap,
    total_volume: url.totalVolume,
    anchor_text: {
      exact: url.anchorTextBuckets.exactMatch,
      partial: url.anchorTextBuckets.partialMatch,
      generic: url.anchorTextBuckets.other,
    },
  };
};

const formatUrlList = (urls: string): Combination[] => urls.split(',').map((url) => ({ url, keywords: [], location_code: null }));

const UrlValidator = yup.string().url().required();
const DomainValidator = yup
  .string()
  .matches(/^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,6}$/)
  .required();

const formatLocations = (locations: FetchLocation[] | undefined) => {
  if (!locations) {
    return [];
  }

  return locations.map(({ name, code, type }) => {
    const output = { value: String(code) };

    if (!type || type.toLowerCase() === 'city') {
      return { ...output, label: name || '?' };
    }

    return { ...output, label: `${name || '?'} [${type}]` };
  });
};

export {
  getBaseUrl,
  parseUrls,
  parseCombinations,
  getLinksToBuild,
  getCloseToGap,
  getLinksState,
  transformCampaignUrlToScenarioUrl,
  formatUrlList,
  UrlValidator,
  DomainValidator,
  trimmer,
  formatLocations,
};
