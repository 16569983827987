import { Button } from '@/components';
import { FC } from 'react';
import cn from '@/utils/style';

type OrganicResearchTableFooterProps = {
  onConfirm: () => void;
  onFetchAdditional?: () => void;
  isLoading?: boolean;
  confirmDisabled?: boolean;
  amount: number;
  className?: string;
};

const OrganicResearchTableFooter: FC<OrganicResearchTableFooterProps> = ({
  className,
  onConfirm,
  isLoading = false,
  amount,
  onFetchAdditional = null,
  confirmDisabled = false,
}) => {
  if (isLoading) {
    return null;
  }

  return (
    <div className={cn('flex items-center justify-between', className)}>
      <p>Selected - {amount} URLs</p>
      <div className='flex items-center justify-end gap-4'>
        {onFetchAdditional && (
          <Button variant='outline-light' onClick={onFetchAdditional}>
            Fetch Additional
          </Button>
        )}
        <Button disabled={confirmDisabled} onClick={onConfirm}>
          Confirm URLs
        </Button>
      </div>
    </div>
  );
};

export default OrganicResearchTableFooter;
