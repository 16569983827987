import { Checkbox } from '@/components';
import { round } from '@/utils/numbers';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
const columnHelper = createColumnHelper<AnchorTextBatchWithTotals>();

type AnchorTextBatchWithTotals = AnchorTextBatch & {
  totals: {
    match_type: {
      exact: number;
      generic: number;
      partial: number;
    };
  };
};

const getLinkTotal = (row: Row<AnchorTextBatchWithTotals>) => row.original.urls?.reduce((acc, curr) => acc + curr.exact_match + curr.partial_match + curr.generic_match, 0);

const getLinkPercentage = (links: number, row: Row<AnchorTextBatchWithTotals>) => round((links / getLinkTotal(row)) * 100, 2);

type AnchorMatchCellProps = {
  links: number;
  row: Row<AnchorTextBatchWithTotals>;
};

const AnchorMatchCell = ({ links, row }: AnchorMatchCellProps) => {
  const { exact, generic, partial } = row.original.totals.match_type;
  const total = exact + generic + partial;
  const percentage = getLinkPercentage(links, row);
  const percentageText = isNaN(percentage) ? '' : `(${percentage}%)`;
  const linksText = total === 0 ? '-' : `${links}`;
  return (
    <p className='text-left'>
      Links: {linksText} <span className='text-slate-400'>{`${percentageText}`}</span>
    </p>
  );
};

const useAnchorTextBatchColumns = () => {
  const navigate = useNavigate();
  const handleGoToBatch = useCallback((id: number) => navigate('../anchor-text-results/' + id), [navigate]);

  return useMemo(
    () => [
      columnHelper.display({
        header: ({ table }) => <Checkbox checked={table.getIsAllRowsSelected()} onChange={table.getToggleAllRowsSelectedHandler()} />,
        cell: ({ row }) => <Checkbox checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />,
        id: 'checked',
        size: 10,
      }),
      columnHelper.accessor('title', {
        header: () => <p className='text-left'>Batch Name</p>,
        cell: ({ getValue, row }) => {
          const defaultName = `Anchor Text Batch ${row.original.id}`;
          return <p className='text-left'>{getValue() || defaultName}</p>;
        },
      }),
      columnHelper.accessor('totals.match_type.exact', {
        header: () => <p className='text-left'>Exact Match</p>,
        cell: ({ getValue, row }) => {
          return <AnchorMatchCell links={getValue()} row={row} />;
        },
      }),
      columnHelper.accessor('totals.match_type.partial', {
        header: () => <p className='text-left'>Partial Match</p>,
        cell: ({ getValue, row }) => <AnchorMatchCell links={getValue()} row={row} />,
      }),
      columnHelper.accessor('totals.match_type.generic', {
        header: () => <p className='text-left'>Generic Match</p>,
        cell: ({ getValue, row }) => <AnchorMatchCell links={getValue()} row={row} />,
      }),
      columnHelper.display({
        id: 'total_match',
        header: () => <p className='text-left'>Total Match</p>,
        cell: ({ row }) => {
          const { exact, generic, partial } = row.original.totals.match_type;
          const total = exact + generic + partial;
          const sum = total === 0 ? '-' : `${Math.round((total * 100) / total)}%`;
          return <p className='text-left'>{sum}</p>;
        },
      }),
      columnHelper.display({
        id: 'anchor_text_count',
        header: () => <p className='text-left'>Anchor Text Count</p>,
        cell: ({ row }) => {
          const term = row.original.selected_term;
          const { exact, generic, partial } = row.original.totals.match_type;
          const total = exact + generic + partial;
          return <p className='text-left'>{total * term}</p>;
        },
      }),
      columnHelper.accessor('allocations', {
        header: () => <p className='text-left '>Keywords Count</p>,
        cell: ({ getValue }) => {
          const keywordsTotal = getValue().filter((keyword) => keyword.anchorsTotal !== 0);
          return <p className='text-left'>{keywordsTotal.length}</p>;
        },
      }),
      columnHelper.display({
        id: 'actions',
        cell: ({ row }) => (
          <button onClick={() => handleGoToBatch(row.original.id)}>
            <ArrowRightIcon className='size-5 text-slate-400' />
          </button>
        ),
        size: 5,
      }),
    ],
    [handleGoToBatch],
  );
};

export default useAnchorTextBatchColumns;
