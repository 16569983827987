import cn from '@/utils/style';
import { StarIcon } from '@heroicons/react/24/outline';
import { StarIcon as SolidStarIcon } from '@heroicons/react/24/solid';
import { FC, MouseEvent, useState } from 'react';

interface PriorityCellProps {
  val: number;
  onClick: (val: number) => void;
  className?: string;
  disabled?: boolean;
}

const NO_PRIORITY = 0;
const PRIORITY = 1;
const HIGH_PRIORITY = 2;

const priorityStateMachine: Record<number, number> = {
  [NO_PRIORITY]: PRIORITY,
  [PRIORITY]: HIGH_PRIORITY,
  [HIGH_PRIORITY]: NO_PRIORITY,
};

const PriorityCell: FC<PriorityCellProps> = ({ val, onClick, className, disabled = false }) => {
  const [priorityVal, setPriorityVal] = useState(val);

  const handlePriorityClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const newPriority = priorityStateMachine[priorityVal];

    setPriorityVal(newPriority);
    onClick(newPriority);
  };

  return (
    <button className={cn('cursor-pointer', className, disabled && 'cursor-not-allowed')} onClick={handlePriorityClick} disabled={disabled}>
      {priorityVal === NO_PRIORITY && (
        <div className={cn('flex flex-row items-center justify-center rounded-full border-4 border-sky-500 p-0.5')}>
          <StarIcon className={cn('w-4 text-sky-500')} />
        </div>
      )}
      {priorityVal === PRIORITY && (
        <div className={cn('flex flex-row items-center justify-center rounded-full border-4 border-sky-500 bg-sky-500 p-0.5')}>
          <SolidStarIcon className='w-4 text-white' />
        </div>
      )}
      {priorityVal === HIGH_PRIORITY && (
        <div className={cn('flex flex-row items-center justify-center rounded-full border-4 border-amber-400 bg-amber-400 p-0.5')}>
          <SolidStarIcon className='w-4 text-white' />
        </div>
      )}
    </button>
  );
};

export default PriorityCell;
