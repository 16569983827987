import { LoadingScreen, ProgressBar, ErrorFallback, OrganicResearchModal, Loading, DataPartnersFooter } from '@/components';
import store from '@/store';
import campaignAPI, { useGuestAnalysisMutation } from '@/store/campaign/campaign.api';
import { FC, Suspense, useState, Fragment, useEffect, useMemo, useCallback } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useToggle } from '@/hooks/toggle';
import { trimmer, DomainValidator, UrlValidator } from '@/utils/urls';
import { Await, LoaderFunction, defer, useLoaderData, useParams, useNavigate } from 'react-router-dom';
import { ScenarioReviewBody, ScenarioReviewProvider } from '../Campaigns/pages/ScenarioReviewPage/components';
import { base62Decode, base62Encode } from '@/utils/baseX';
import { ToastContainer } from 'react-toastify';
import { AnalysisTable } from './components';
import { runStatusMapper } from '@/utils/campaigns';
import cn from '@/utils/style';
import * as yup from 'yup';

interface GuestAnalysisPageParams {
  domainEncoded: string;
}

interface GuestFormValues {
  urls: string;
}

const guestAnalysisValidationSchema: yup.SchemaOf<GuestFormValues> = yup.object({
  urls: yup.string().min(2).required(),
});

interface MainViewProps {
  data: GuestAnalysisData;
}

const MainView: FC<MainViewProps> = ({ data: { campaign, analysis, run } }) => {
  const navigate = useNavigate();
  const { value: isOrganicResearchModalOpen, toggle: toggleOrganicResearchModal } = useToggle({ initialValue: true });
  const [guestAnalysis, { isLoading: guestAnalysisIsLoading }] = useGuestAnalysisMutation();
  const searchParams = new URLSearchParams(document.location.search);
  const token = searchParams.get('t') || undefined;

  const [analysisData, setAnalysisData] = useState(analysis);
  const [runData, setRunData] = useState(run);
  const [campaignData, setCampaignData] = useState(campaign);

  const { domainEncoded } = useParams() as { domainEncoded: string };
  const domainDecoded = base62Decode(domainEncoded || '');
  const domain = UrlValidator.isValidSync(domainDecoded) || DomainValidator.isValidSync(domainDecoded) ? domainDecoded : undefined;

  const fetchStatusText = useMemo(() => {
    if (runData && runData.status) {
      return runStatusMapper[runData.status as RunStatus];
    }

    return '';
  }, [runData]);

  const fetchGuestAnalysis = useCallback(async ({ urls, token, domain, campaignId }: { urls: string; token?: string; domain?: string; campaignId?: number }) => {
    const response = await guestAnalysis({ urls: (urls || '').split(',').map(trimmer).filter(Boolean), token, domain, campaignId }).unwrap();

    const { analysis, campaign, run } = response;

    setAnalysisData(analysis);
    setCampaignData(campaign);
    setRunData(run);

    return response;
  }, []);

  useEffect(() => {
    if (!campaignData.id || !token || !domain || ['error', 'done'].includes(runData.status)) {
      return () => {};
    }

    const interval = setInterval(async () => await fetchGuestAnalysis({ campaignId: campaignData.id, token, domain, urls: '' }), 2000);

    return () => clearInterval(interval);
  }, [campaignData, runData, token, domain]);

  const handleSubmit = async ({ urls }: { urls: string }) => {
    const { analysis, campaign, run } = await fetchGuestAnalysis({ urls, token, domain });

    searchParams.delete('c');
    searchParams.set('c', base62Encode(String(campaign.id)));

    navigate(`?${searchParams.toString()}`);
  };

  const formik = useFormik<GuestFormValues>({
    onSubmit: handleSubmit,
    initialValues: {
      urls: '',
    },
    validationSchema: guestAnalysisValidationSchema,
  });

  const handleConfirm = () => {
    formik.submitForm();
    toggleOrganicResearchModal(false);
  };

  const isQueued = campaignData.id && runData.status === 'queued';
  const isNewCampaign = !campaignData.id;
  const fetchProgress = Math.round((runData.fetch_progress || 0) * 100) / 100;
  const isFetching = campaignData.id && !['done', 'error', 'queued'].includes(runData.status);
  const isDone = campaignData.id && runData.status === 'done';

  return (
    <Fragment>
      <div className='relative flex min-h-screen w-full flex-col items-center'>
        <header className='flex w-full items-center justify-center bg-presentation-background bg-cover bg-center bg-no-repeat'>
          <div className='z-10 py-14 text-sky-600'>
            <p className='text-xl font-semibold'>Powered by</p>
            <p className='text-4xl font-bold'>Linklaunch</p>
          </div>
        </header>
        <div className='flex w-full flex-col items-center'>
          <p className='mt-8 text-xl font-bold'>{campaignData.name || 'Sneak Peek!'}</p>
          <p className='mb-8 text-xl'>{campaignData.client_domain || domain}</p>
        </div>

        {isNewCampaign && (
          <div className='flex w-full flex-col items-center'>
            {!domain && <p className='mt-16 text-red-500'>Domain/URL is not valid, please generate a new link</p>}

            {domain && isOrganicResearchModalOpen && (
              <Fragment>
                <FormikProvider value={formik}>
                  <OrganicResearchModal isGuest name='urls' isOpen url={domain} country={'us'} confirmDisabled={guestAnalysisIsLoading} onConfirm={handleConfirm} token={token} />
                </FormikProvider>
              </Fragment>
            )}
          </div>
        )}

        {isQueued && (
          <div className='flex w-full flex-col items-center'>
            <Loading className='mt-4' />
            <p className='mt-2 text-lg'>Analysis job queued...</p>
          </div>
        )}

        {isFetching && (
          <div className='flex w-full flex-col items-center'>
            {runData.status !== 'done' && (
              <Fragment>
                <ProgressBar
                  progress={fetchProgress * 100}
                  showProgress={false}
                  color={cn('h-3', runData.status === 'error' ? 'bg-red-400' : 'bg-green-700')}
                  baseColor='bg-gray-300 h-3'
                  className='mb-px mt-0 w-full max-w-6xl px-4'
                />
                {fetchProgress !== 1 && <p className='mt-2 font-bold'>{Math.round(fetchProgress * 100)}%</p>}
                <p className='mt-2 px-3 text-sm font-semibold'>{fetchStatusText}</p>
              </Fragment>
            )}
          </div>
        )}

        {isDone && <AnalysisTable data={analysisData} />}

        <div className='absolute bottom-0 w-full px-2 py-2 text-center'>
          <DataPartnersFooter className='justify-around' />
        </div>
      </div>
    </Fragment>
  );
};

const GuestAnalysisPage: FC = () => {
  const { data, error } = useLoaderData() as PageLoaderDefData<GuestAnalysisPageData>;

  return (
    <Suspense fallback={<LoadingScreen />}>
      <ToastContainer pauseOnHover newestOnTop />
      <Await resolve={data} errorElement={<ErrorFallback />}>
        {(resolvedData) => <MainView data={resolvedData} />}
      </Await>
    </Suspense>
  );
};

type GuestAnalysisPageData = {};

const guestAnalysisPageLoader: LoaderFunction = async ({ params }) => {
  const { domainEncoded } = params;
  const searchParams = new URLSearchParams(document.location.search);
  const token = searchParams.get('t') || undefined;
  const campaignIdEncoded = searchParams.get('c');

  if (!campaignIdEncoded) {
    return defer({ data: new Promise((r) => r({ analysis: {}, campaign: {}, run: {} } as GuestAnalysisData)) });
  }

  try {
    const data = store
      .dispatch(campaignAPI.endpoints.guestAnalysis.initiate({ campaignId: Number(base62Decode(campaignIdEncoded)), domain: base62Decode(domainEncoded || ''), token, urls: [] }))
      .unwrap();

    return defer({ data });
  } catch (error) {
    return { error: error as APIError };
  }
};

export { guestAnalysisPageLoader };
export type { GuestAnalysisPageParams };
export default GuestAnalysisPage;
